@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

* {
	font-family: Open Sans;
}

.footer-distributed {
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
	display: inline-block;
	vertical-align: top;
}


.footer-distributed .footer-left {
	width: 40%;
}

.footer-distributed .footer-links {
	color: black;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a {
	display: block;
	line-height: 1.8;
	font-weight: 400;
	text-decoration: none;
}

.footer-company-name {
	color: #222;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}


.footer-distributed .footer-center {
	width: 35%;
}


.footer-distributed .footer-center p {
	display: inline-block;
	color: black;
	font-weight: 400;
	vertical-align: middle;
	margin: 0;
}

.footer-distributed .footer-center p span {
	display: block;
	font-weight: normal;
	font-size: 14px;
	line-height: 2;
}

.footer-distributed .footer-center p a {
	color: lightseagreen;
	text-decoration: none;
	;
}

.footer-distributed .footer-links .link-1:before {
	content: none;
}


.footer-distributed .footer-right {
	width: 20%;
}

.footer-distributed .footer-company-about {
	line-height: 20px;
	color: #92999f;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-icons {
	margin-top: 25px;
}

.footer-company-about strong {
	font-size: x-large;
	color: black;
}

.footer-distributed .footer-icons a {
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color: #33383b;
	border-radius: 2px;
	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;
	margin-right: 3px;
	margin-bottom: 5px;
}

.link a {
	display: block;
	font-size: small;
	font-weight: normal;
}

.rights-1 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.rights-1 p {
	padding-top: 6%;
	padding-left: 65%;
}

@media (max-width: 880px) {

	.footer-distributed {
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed {
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i {
		margin-left: 0;
	}

}