header {
    width: 100%;
    height: 15%;
    background-color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    text-align: justify;
}

.navbar {
    margin: 10px;
    max-height: 80px;
    text-align: center;
}

.navbar>li {
    display: inline-block;
    margin: 30px;
    height: 35px;
    text-transform: uppercase;
}

.link-nav {
    text-decoration: none;
    color: black;
}

.link-nav:hover {
    color: red;
}
.contact{
    padding: 5px 20px;
    border: 1px solid red;
    background: transparent;
    border-radius: 3px;
    color: red;
}
.contact:hover{
    background-color: red;
    color: white;
}