.home {
    height: 60%;
    display: flex;
    text-align: left;
    padding-left: 2%;
    padding-top: 2%;
}

.homeImage {
    height: 100%;
    width: 100%;
    padding-right: 3%;
}

.homeImage img {
    border-radius: 10px;
}

.home button {
    background-color: rgb(246, 45, 45);
    width: 30%;
    text-align: center;
    font-size: x-large;
    border-radius: 10px;
    border: 1px solid rgb(246, 45, 45);
}

.home button:hover {
    background-color: white;
}

.service-home {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    text-align: left;
    padding-right: 3%;
    padding-left: 2%;
}

.service-home img {
    border-radius: 5px;
}

.service-home h4 {
    text-align: center;
}

.service-card button {
    background-color: rgb(246, 45, 45);
    width: 18%;
    text-align: center;
    font-size: large;
    border-radius: 6px;
    border: 1px solid rgb(246, 45, 45);
}
.service-card button:hover{
    background-color: white;
}
.service-card{
    padding-top: 10%;
}